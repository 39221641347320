import './App.css';
import Life from './Life';

function App() {
  return (
	  <Life />
  );
}

export default App;
